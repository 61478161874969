import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  TextField,
  IconButton,
  Tooltip,
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Switch,
} from "@mui/material";
import { MUITextField } from '@mui/material';

import {
  AddCircle as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import axios from "axios";
import PincodeStats from "./components/PincodeStats";
import ToggleButton from "./components/ToggleButton";

const PincodeSrc = () => {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [newPincode, setNewPincode] = useState("");
  const [newFullName, setNewFullName] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newUserId, setNewUserId] = useState("");
  const [type, setType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [switchState, setSwitchState] = useState(() => {
    const savedState = localStorage.getItem("switchState");
    return savedState ? JSON.parse(savedState) : {};
  });

  useEffect(() => {
    fetchPincodes();
  }, [type]);

  const fetchPincodes = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://api.medstown.com/customer/getpincode?type=${type}`
      );
      setData(res?.data.reverse() || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedState = localStorage.getItem("switchState");
    if (savedState) {
      setSwitchState(JSON.parse(savedState));
    }
  }, [data]);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const handleChange = (row) => async (event) => {
    const newStatus = event.target.checked;
    setSwitchState((prevState) => {
      const updatedState = { ...prevState, [row.areaPincode]: newStatus };
      localStorage.setItem("switchState", JSON.stringify(updatedState));
      return updatedState;
    });

    const requestData = {
      areaPincode: row.areaPincode,
      newUserPincode: row.newUserPincode,
    };

    try {
      const res = await axios.post(
        "https://api.medstown.com/customer/pincode/toggle",
        requestData
      );
      setSwitchState((prevState) => {
        const updatedState = {
          ...prevState,
          [row.areaPincode]: res.data.isActive,
        };
        localStorage.setItem("switchState", JSON.stringify(updatedState));
        return updatedState;
      });
    } catch (err) {
      console.error("Error updating status:", err);
      setSwitchState((prevState) => {
        const updatedState = { ...prevState, [row.areaPincode]: !newStatus };
        localStorage.setItem("switchState", JSON.stringify(updatedState));
        return updatedState;
      });
    }
  };

  const handleDeletePincode = async (row) => {
    try {
      await axios.delete("https://api.medstown.com/customer/delete/pincode", {
        data: {
          areaPincode: row.areaPincode,
          newUserPincode: row.newUserPincode,
        },
      });
      fetchPincodes();
    } catch (error) {
      setError("Failed to delete pincode");
      console.error("Delete Error:", error);
    }
  };

  const handleDownload = () => {
    window.location.href =
      "https://api.medstown.com/admin/pincodes/download-csv";
  };




  const handleAddPincode = async () => {
    try {
      const response = await axios.post("https://api.medstown.com/customer/addpincode", {
        areaPincode: newPincode,
        fullName: newFullName,
        phone: newPhone,
        userId: newUserId,
      });
  
      if (response.data) {
        // Update switch state
        setSwitchState((prevState) => {
          const updatedState = { ...prevState, [newPincode]: true };
          localStorage.setItem("switchState", JSON.stringify(updatedState));
          return updatedState;
        });
  
        // Reset form fields
        setNewPincode("");
        setNewFullName("");
        setNewPhone("");
        setNewUserId("");
  
        // Refresh the data
        fetchPincodes();
        setOpenModal(false);
      }
    } catch (error) {
      setError("Failed to add pincode");
      console.error(error);
    }
  };
  


  useEffect(() => {
    const savedState = localStorage.getItem("switchState");
    if (savedState) {
      setSwitchState(JSON.parse(savedState));
    }
  }, [data]);



  const handleToggleChange = async (state, row) => {
    try {
      const requestData = {
        areaPincode: row?.areaPincode,
        newUserPincode: row?.newUserPincode,
        status: state,
      };
      const res = await axios.post("https://api.medstown.com/customer/pincode/toggle", requestData);
      console.log(res.data)
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  







  const tableColumns = [
    { field: "fullName", headerName: "Request by", width: 170 },
    { field: "phone", headerName: "Phone Number", width: 170 },
    { field: "areaPincode", headerName: "Active Pin-code", width: 170 },
    { field: "newUserPincode", headerName: "Request Pin-Code", width: 170 },
    {
      field: "createdAt",
      headerName: "Date & Time",
      width: 170,
      valueGetter: (params) => {
        const date = new Date(params.row.createdAt);
        return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Tooltip title="Delete Pincode">
          <IconButton onClick={() => handleDeletePincode(params.row)}>
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "Switch",
      headerName: "Switch",
      width: 150,
      renderCell: (params) => (
        <ToggleButton  initial={switchState[params.row.newUserPincode] || false}    row={params?.row} onToggle={handleToggleChange} />
      ),
    },
  ];






  return (
    <div style={{ padding: "20px" }}>
      <PincodeStats setType={setType} type={type} />
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchText}
        onChange={handleSearchTextChange}
        style={{ marginBottom: "20px" }}
      />
      <Stack direction="row" spacing={2} mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
        >
          Add New Pincode
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          sx={{ borderRadius: "4px", fontSize: "14px", width: "200px" }}
        >
          Download CSV
        </Button>
      </Stack>
      <div style={{ height: "500px" }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={filteredRows}
            columns={tableColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            sx={{ width: "100%" }}
            getRowId={(row) => row._id}
          />
        )}
      </div>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ ...modalStyle }}>
          <Typography variant="h6">Add New Pincode</Typography>
          <TextField
            label="New Pincode"
            variant="outlined"
            fullWidth
            value={newPincode}
            onChange={(e) => setNewPincode(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            value={newFullName}
            onChange={(e) => setNewFullName(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            label="Phone"
            variant="outlined"
            fullWidth
            value={newPhone}
            onChange={(e) => setNewPhone(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            label="User Id"
            variant="outlined"
            fullWidth
            value={newUserId}
            onChange={(e) => setNewUserId(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPincode}
          >
            Add Pincode
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpenModal(false)}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


export default PincodeSrc;
