import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, Typography, Box, CircularProgress, Button } from "@mui/material";
import axios from "axios";

const SearchHistories = () => {
    const [searchText, setSearchText] = useState("");
    const [debouncedSearchText, setDebouncedSearchText] = useState("");
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchText(searchText);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [searchText]);

    const fetchSearchHistory = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const res = await axios.get("https://api.medstown.com/pharmacy/medicine/search/history", {
                params: { pincode: debouncedSearchText, limit: 10, page },
            });
            
            const { list, totalPages } = res.data?.data || {};
            const validTotalPages = totalPages || 0;

            if (Array.isArray(list)) {
                setData(list);
                setTotalPages(validTotalPages);
                setCurrentPage(Math.min(page, validTotalPages));
            } else {
                setData([]);
                setTotalPages(0);
            }
        } catch (error) {
            setError("Failed to fetch data. Please try again.");
            console.error("API Error:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSearchHistory(1);
    }, [debouncedSearchText]);

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            fetchSearchHistory(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            fetchSearchHistory(currentPage - 1);
        }
    };

    const tableColumns = [
        { field: "search", headerName: "Search Term", width: 200, flex: 1 },
        { field: "user_id", headerName: "User ID", width: 500, renderCell: (params) => params.value || "N/A" },
        { field: "pincode", headerName: "Pincode", width: 200 },
        {
            field: "searchedAt",
            headerName: "Created Date & Time",
            width: 200,
            valueGetter: (params) => {
                const date = new Date(params.row.searchedAt);
                return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
            },
        },
    ];

    return (
        <Box padding={3} sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {error && (
                <Typography color="error" mb={2}>
                    {error}
                </Typography>
            )}

            <TextField
                label="Search by Pincode"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={handleSearchTextChange}
                sx={{ mb: 2 }}
            />

            <Box sx={{ flex: 1, minHeight: 400 }}>
                <DataGrid
                    rows={data.map((row, index) => ({
                        ...row,
                        id: row._id || `temp-id-${index}`,
                    }))}
                    columns={tableColumns}
                    paginationMode="server"
                    rowCount={totalPages * 10}
                    page={currentPage - 1}
                    pageSize={10}
                    loading={loading}
                    disableSelectionOnClick
                    hideFooterPagination
                    sx={{
                        '& .MuiDataGrid-virtualScroller': {
                            overflow: 'hidden',
                        },
                    }}
                />
            </Box>

            <Box display="flex" justifyContent="center" mt={2} gap={2} alignItems="center">
                <Button
                    variant="contained"
                    onClick={handlePrevPage}
                    disabled={currentPage <= 1 || totalPages === 0}
                >
                    Previous
                </Button>
                
                <Typography variant="body1">
                    {totalPages === 0 ? 'No results' : `Page ${currentPage} of ${totalPages}`}
                </Typography>

                <Button
                    variant="contained"
                    onClick={handleNextPage}
                    disabled={currentPage >= totalPages || totalPages === 0}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
};

export default SearchHistories;