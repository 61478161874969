import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Autocomplete,
  Switch,
} from "@mui/material";
import axios from "axios";
import debounce from "lodash.debounce";

const steps = [
  "Discount Details",
  "Pincode & Target",
  "Usage & Validity",
  "Collaborate Partner & status",
];

const PromoCodeForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    variant: "coupon",
    code: "",
    discount_type: "discount",
    discount: "",
    max_discount_amount: "",
    fixedPrice: "",
    minimum_bill_amount: "",
    pincode_source: "all",
    pincodes: [],
    target_type: "all",
    target_users: [],
    number_of_uses: "",
    used_type: "once",
    valid_until: "",
    description: "",
    collabrate_partner: { entity_id: "", title: "", img_url: "" },
    is_live: true,
    is_public: false ,
  });

  const [loading, setLoading] = useState(false);
  const [entityLoading, setEntityLoading] = useState(false);
  const [entities, setEntities] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [users, setUsers] = useState([]);
  const [userLoading, setUserLoading] = useState(false);

  // Fetch entities with debounce
  const fetchEntities = debounce(async (searchTerm) => {
    if (!searchTerm) return;
    setEntityLoading(true);
    try {
      const response = await axios.get(
        `https://api.medstown.com/offer/entities/search?title=${searchTerm}`
      );
      setEntities(response.data.data);
    } catch (err) {
      console.error("Error fetching entities:", err);
    } finally {
      setEntityLoading(false);
    }
  }, 500);

  const fetchUsers = debounce(async (searchTerm) => {
    if (!searchTerm) return;
    setUserLoading(true);
    try {
      const response = await axios.get(
        `https://api.medstown.com/offer/user/search?slug=${searchTerm}`
      );
      setUsers(response.data.data);
    } catch (err) {
      console.error("Error fetching users:", err);
    } finally {
      setUserLoading(false);
    }
  }, 500);

  const handleEntityChange = (_, selectedEntity) => {
    if (selectedEntity) {
      setFormData((prev) => ({
        ...prev,
        collabrate_partner: {
          entity_id: selectedEntity._id,
          title: selectedEntity.title,
          img_url: selectedEntity.img_url,
        },
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e) => {
    console.log(formData, "formData");
    // e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(
        "https://api.medstown.com/offer/promocode",
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 201) {
        setSuccess("Promocode created successfully!");
        setFormData({
          variant: "coupon",
          code: "",
          discount_type: "discount",
          discount: "",
          max_discount_amount: "",
          fixedPrice: "",
          minimum_bill_amount: "",
          pincode_source: "all",
          pincodes: [],
          target_type: "all",
          target_users: [],
          number_of_uses: "",
          used_type: "multiple",
          valid_until: "",
          description: "",
          collabrate_partner: { entity_id: "", title: "", img_url: "" },
        });
        setActiveStep(0);
      }
    } catch (err) {
      setError(
        err.response?.data?.error_code ||
          "Failed to create promocode. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ maxWidth: 900, margin: "auto", mt: 5, p: 3 }}>
      <CardContent>
        <Typography variant="h5" mb={3} gutterBottom>
          Create New Promocode
        </Typography>

        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <form>
          <Grid container spacing={2} sx={{ mt: 6}}>
            {activeStep === 0 && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Promocode"
                    name="code"
                    value={formData.code}
                    onChange={handleChange}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Discount Type"
                    name="discount_type"
                    value={formData.discount_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="discount">Discount</MenuItem>
                    <MenuItem value="fixedPrice">Fixed Price</MenuItem>
                  </TextField>
                </Grid>

                {formData.discount_type === "discount" && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Discount (%)"
                        name="discount"
                        value={formData.discount}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Max Discount Amount"
                        name="max_discount_amount"
                        value={formData.max_discount_amount}
                        onChange={handleChange}
                      />
                    </Grid>
                  </>
                )}

                {formData.discount_type === "fixedPrice" && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Fixed Price Discount"
                      name="fixedPrice"
                      value={formData.fixedPrice}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
              </>
            )}

            {activeStep === 1 && (
              <>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Pincode Source"
                    name="pincode_source"
                    value={formData.pincode_source}
                    onChange={handleChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="specific">Specific</MenuItem>
                  </TextField>
                </Grid>
                {formData.pincode_source === "specific" && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Enter Pincodes (comma separated)"
                      name="pincodes"
                      value={formData.pincodes.join(",")}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          pincodes: e.target.value
                            .split(",")
                            .map((pincode) => pincode.trim()),
                        })
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Target Type"
                    name="target_type"
                    value={formData.target_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="specific">Specific</MenuItem>
                  </TextField>
                </Grid>

                {formData.target_type === "specific" && (
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={users}
                      getOptionLabel={(option) =>
                        `${option.fullName} (${option.phone})`
                      } // Adjust this based on actual API response
                      onInputChange={(_, value) => fetchUsers(value)}
                      onChange={(_, selectedUsers) =>
                        setFormData((prev) => ({
                          ...prev,
                          target_users: selectedUsers.map((user) => user._id), // Store only user IDs
                        }))
                      }
                      loading={userLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Users"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {userLoading ? (
                                  <CircularProgress size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </>
            )}

            {activeStep === 2 && (
              <>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Used Type"
                    name="used_type"
                    value={formData.used_type}
                    onChange={handleChange}
                  >
                    <MenuItem value="once">Once's</MenuItem>
                    <MenuItem value="new_user">New Users</MenuItem>
                    <MenuItem value="multiple">Multiple</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Number of Uses"
                    name="number_of_uses"
                    type="number"
                    value={formData.number_of_uses}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Minimum Bill Amount"
                    name="minimum_bill_amount"
                    value={formData.minimum_bill_amount}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Valid Until"
                    name="valid_until"
                    InputLabelProps={{ shrink: true }}
                    value={formData.valid_until}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    multiline
                    rows={3}
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                </Grid>
              </>
            )}

            {activeStep === 3 && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={entities}
                    getOptionLabel={(option) => option.title}
                    onInputChange={(_, value) => fetchEntities(value)}
                    onChange={handleEntityChange}
                    loading={entityLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Partner"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {/* Publish Switch */}
                <Grid item xs={12} display="flex" alignItems="center">
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    Publish
                  </Typography>
                  <Switch
                    name="Publish"
                    checked={formData.is_live}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        is_live: e.target.checked,
                      });
                    }}
                    color="default"
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "green",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "green",
                        },
                      "& .MuiSwitch-track": {
                        backgroundColor: "gray",
                      },
                    }}
                  />
                   <Typography variant="body1" sx={{ ml: 2 ,mr : 1}}>
                    Keep Public
                  </Typography>
                  <Switch
                    name="Keep Public"
                    checked={formData.is_public}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        is_public: e.target.checked,
                      });
                    }}
                    color="default"
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "green",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "green",
                        },
                      "& .MuiSwitch-track": {
                        backgroundColor: "gray",
                      },
                    }}
                  />
                </Grid>

            
              </>
            )}
          </Grid>

          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Button
              type="button"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            ) : (
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </div>
        </form>

        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="success">{success}</Typography>}
      </CardContent>
    </Card>
  );
};

export default PromoCodeForm;
