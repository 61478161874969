import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Grid,
} from "@mui/material";
import { useForm, Controller, useWatch } from "react-hook-form";
import axios from "axios";

const CreditWallet = () => {
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      type: "",
      pincodes: "",
      amount: "",
      userIdentifier: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);

  const selectedType = useWatch({ control, name: "type" });

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    setResponse(null);

    if (data.pincodes) {
      data.pincodes = data.pincodes
        .split(",")
        .map((pincode) => pincode.trim())
        .filter(Boolean);
    } else {
      delete data.pincodes;
    }

    try {
      const res = await axios.post(
        "https://api.medstown.com/wallet/add-multiple",
        data
      );
      setResponse(res.data);
      setUsers(res.data.creditedUsers || []);
      reset();
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Credit Free Cash for Users
      </Typography>

      {error && <Alert severity="error">{error}</Alert>}
      {response && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {response.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          sx={{ mt: 2, width: { xs: "100%", sm: "400px" } }}
        >
          {/* Type Selection */}
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              rules={{ required: "Type is required" }}
              render={({ field }) => (
                <TextField {...field} select label="Select Type" fullWidth>
                  <MenuItem value="pincode_wise">Pincode Wise</MenuItem>
                  {/* <MenuItem value="new_users">New Users</MenuItem> */}
                  {/* <MenuItem value="retargeted_users">Retargeted Users</MenuItem> */}
                  <MenuItem value="specific_user">Specific User</MenuItem>
                </TextField>
              )}
            />
          </Grid>

          {/* Pincodes Input (Shown for `pincode_wise` and `new_users`) */}
          {(selectedType === "pincode_wise" ||
            selectedType === "new_users") && (
            <Grid item xs={12}>
              <Controller
                name="pincodes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Pincodes (comma separated)"
                    fullWidth
                    placeholder="E.g., 506002, 500081"
                  />
                )}
              />
            </Grid>
          )}

          {/* User Identifier (Shown for `specific_user`) */}
          {selectedType === "specific_user" && (
            <Grid item xs={12}>
              <Controller
                name="userIdentifier"
                control={control}
                rules={{ required: "User ID or Phone is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="User ID / Phone"
                    fullWidth
                    placeholder="Enter user ID or mobile number"
                  />
                )}
              />
            </Grid>
          )}

          {/* Amount Input */}
          <Grid item xs={12}>
            <Controller
              name="amount"
              control={control}
              rules={{
                required: "Amount is required",
                min: { value: 1, message: "Amount must be greater than zero" },
              }}
              render={({ field }) => (
                <TextField {...field} type="number" label="Amount" fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Credit Wallet"}
            </Button>
          </Grid>
        </Grid>
      </form>

      {users.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom sx={{ p: 2 }}>
            Credited Users
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user.user_id}</TableCell>
                  <TableCell>₹{user.amount}</TableCell>
                  <TableCell>{user.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default CreditWallet;
