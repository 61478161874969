import React, { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import Divider from "@mui/material/Divider";

const Usertotal = ({ setType, type }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function getData() {
    setLoading(true);
    setError(false);
    try {
      const res = await axios.get(
        `https://api.medstown.com/customer/userAnylatics`
      );
      if (res.data) {
        setData(res.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <p style={{ textAlign: "center" }}>Loading... </p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.errorContainer}>
        <p style={{ textAlign: "center", color: "red" }}>
          Failed to load data. Please try again later.
        </p>
        <button
          onClick={() => {
            setError(false);
            getData();
          }}
        >
          Retry
        </button>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div style={styles.divContainer}>
      <div
        style={styles.cardWrapper}
        onClick={() => {
          if (type !== "all") {
            setType("all");
          }
        }}
      >
        <CardContent
          style={{
            ...styles.cardContent,
            border: `2px solid ${type === "all" ? "#014D4D" : "transparent"}`,
          }}
        >
          <p className="text-sm font-medium text-gray-600">Total Users</p>
          <p className="font-large text-black-600 text-4xl">
            {data?.total_user}
          </p>
        </CardContent>
      </div>

      

      {/* <div
        style={styles.cardWrapper}
        onClick={() => {
          if (type !== "unique") {
            setType("unique");
          }
        }}
      >
        <CardContent
          style={{
            ...styles.cardContent,
            border: `2px solid ${
              type === "unique" ? "#014D4D" : "transparent"
            }`,
          }}
        >
          <p className="text-sm font-medium text-gray-600">Unique PIN Codes</p>
          <p className="font-large text-black-600 text-4xl">
            {data?.uniqueActivePincodesCount +
              data?.uniqueRequestedPincodesCount}
          </p>
        </CardContent>
      </div> */}

      <Divider orientation="vertical" />

      <div
        style={styles.cardWrapper}
        onClick={() => {
          if (type !== "active") {
            setType("active");
          }
        }}
      >
        <CardContent
          style={{
            ...styles.cardContent,
            border: `2px solid ${
              type === "active" ? "#014D4D" : "transparent"
            }`,
          }}
        >
          <p className="text-sm font-medium text-gray-600">Today Register Users</p>
          <p className="font-large text-black-600 text-4xl">
            {data?.today}
          </p>
        </CardContent>
      </div>

      <Divider orientation="vertical" />

      <div
        style={styles.cardWrapper}
        onClick={() => {
          if (type !== "requested") {
            setType("requested");
          }
        }}
      >
        <CardContent
          style={{
            ...styles.cardContent,
            border: `2px solid ${
              type === "requested" ? "#014D4D" : "transparent"
            }`,
          }}
        >
          <p className="text-sm font-medium text-gray-600">
          This Month Users
          </p>
          <p className="font-large text-black-600 text-4xl">
            {data?.month}
          </p>
        </CardContent>
      </div>
    </div>
  );
};

const styles = {
  divContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    gap: 12,
  },
  cardWrapper: {
    flex: 1,
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  cardContent: {
    padding: "20px",
    textAlign: "center",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  loadingContainer: {
    textAlign: "center",
    padding: "20px",
  },
  errorContainer: {
    textAlign: "center",
    padding: "20px",
    color: "red",
  },
};

export default Usertotal;
