import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, Typography, Box } from "@mui/material";
import axios from "axios";

const Searchhistories = () => {
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    // Fetch Data from API
    const fetchSearchHistory = async () => {
        try {
            const res = await axios.get("https://api.medstown.com/pharmacy/medicine/top-searches");
            setData(res?.data.reverse() || []); // Reverse for recent searches
        } catch (error) {
            setError("Failed to fetch data");
            console.error(error);
        }
    };

    useEffect(() => {
        fetchSearchHistory();
    }, []);

    // Handle Search Input
    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    // Filter Rows Based on Search Input
    const filteredRows = data
        .filter((row) =>
            Object.values(row).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchText.toLowerCase())
            )
        )
        .map((row, index) => ({
            ...row,
            id: row._id || `temp-id-${index}`, // Assign `_id` or a unique fallback ID
        }));

    // Table Columns
    const tableColumns = [
        { field: "_id", headerName: "Search Term medicine", width: 300 },
        { field: "count", headerName: "Search Count", width: 200 },
        { 
            field: "pincodes", 
            headerName: "Pincodes", 
            width: 250, 
            valueGetter: (params) => params.row.pincodes.join(", ") 
        },
        { 
            field: "searchedAt", 
            headerName: "Created Date & Time", 
            width: 220, 
            valueGetter: (params) => {
                const date = new Date(params.row.searchedAt);
                return `${date.toLocaleDateString()} | ${date.toLocaleTimeString()}`;
            }
        }
    ];

    return (
        <Box padding={3}>
            {error && <Typography color="error">{error}</Typography>}

            {/* Search Bar */}
            <TextField
                label="Search"
                variant="outlined"
                fullWidth
                value={searchText}
                onChange={handleSearchTextChange}
                style={{ marginBottom: "20px" }}
            />

            {/* Data Grid */}
            <Box height="700px">
                <DataGrid
                    rows={filteredRows}
                    columns={tableColumns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableSelectionOnClick
                    sx={{ width: "100%" }}
                />
            </Box>
        </Box>
    );
};

export default Searchhistories;
