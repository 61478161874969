import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import PrivateRoute from "layouts/PrivateRoute";

import { MyProvider } from "Contextapi/MyContext";

const App = () => {
  return (
    <MyProvider>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        />

        <Route path="rtl/*" element={<RtlLayout />} />
        <Route path="/" element={<Navigate to="/auth" replace />} />
      </Routes>
    </MyProvider>
  );
};

export default App;
