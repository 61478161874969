import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  CircularProgress,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import { MdEdit, MdVisibility } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const OfferList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    discount_type: "",
    discount: "",
    minimum_bill_amount: "",
    is_live: true,
  });

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.medstown.com/offer/promocode?page=${page}`
      );

      setData(response.data.data.promocodes);
      setTotalPages(Math.ceil(response.data.data.totalPromocodes / 10));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setFormData({
      code: item.code,
      discount_type: item.discount_type,
      discount: item.discount || item.fixedPrice, // Handle both types
      minimum_bill_amount: item.minimum_bill_amount,
      is_live: item.is_live,
    });
    setOpenModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    if (!selectedItem) return;
    setLoading(true);

    try {
      const response = await axios.put(
        `https://api.medstown.com/offer/promocode/${selectedItem._id}`,
        {
          code: formData.code,
          discount_type: formData.discount_type,
          discount: formData.discount,
          minimum_bill_amount: formData.minimum_bill_amount,
          is_live: formData.is_live,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      fetchData(currentPage);
      setOpenModal(false);
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusToggle = async (item, key) => {
    try {
      const updatedStatus = !item[key];
      const response = await axios.put(
        `https://api.medstown.com/offer/promocode/${item._id}`,
        {
          [key]: updatedStatus,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      setData((prevData) =>
        prevData.map((d) =>
          d._id === item._id ? { ...d, [key]: updatedStatus } : d
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="right" alignItems="center" mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/admin/create-offer");
          }}
          sx={{
            borderRadius: "4px",
            fontSize: "14px",
          }}
        >
          Create Coupon
        </Button>
      </Stack>
      <Card sx={{ marginTop: 4, py: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  Coupon Code
                </TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  Description
                </TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  Discount
                </TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  Min Bill Amount
                </TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  {" "}
                  Valid Until
                </TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  Status
                </TableCell>
                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  Public Status
                </TableCell>

                <TableCell style={{ fontWeight: 600, fontSize: 13 }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Box display="flex" justifyContent="center" py={2}>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                data.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>{item.code}</TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 200,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.description}
                    </TableCell>
                    <TableCell>
                      {item.discount_type === "fixedPrice"
                        ? `₹${item.fixedPrice}`
                        : `${item.discount}% (Max ₹${item.max_discount_amount})`}
                    </TableCell>
                    <TableCell>₹{item.minimum_bill_amount}</TableCell>
                    <TableCell>
                      {new Date(item.valid_until).toLocaleDateString("en-GB")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={item.is_live}
                        onChange={() => handleStatusToggle(item, "is_live")}
                        color="default"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green",
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "green",
                            },
                          "& .MuiSwitch-track": {
                            backgroundColor: "gray",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={item.is_public}
                        onChange={() => handleStatusToggle(item, "is_public")}
                        color="default"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green",
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "green",
                            },
                          "& .MuiSwitch-track": {
                            backgroundColor: "gray",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        {/* Edit Button */}
                        <IconButton
                          onClick={() => handleEdit(item)}
                          color="primary"
                        >
                          <MdEdit size={20} />
                        </IconButton>

                        {/* View Button */}
                        <IconButton
                          onClick={() =>
                            navigate(`/admin/offer-details/${item._id}`)
                          }
                          color="secondary"
                        >
                          <MdVisibility size={20} />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
        />

        {/* Edit Modal */}
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Edit Promo Code</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Promo Code"
              name="code"
              fullWidth
              value={formData.code}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="Discount Type"
              name="discount_type"
              fullWidth
              value={formData.discount_type}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="Discount Value"
              name="discount"
              type="number"
              fullWidth
              value={formData.discount}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="Minimum Bill Amount"
              name="minimum_bill_amount"
              type="number"
              fullWidth
              value={formData.minimum_bill_amount}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button onClick={handleUpdate} color="primary">
              {loading ? <CircularProgress size={20} /> : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};

export default OfferList;
