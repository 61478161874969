import React, { useState } from "react";

const ToggleButton = ({ initial = false,row, onToggle }) => {
  const [isOn, setIsOn] = useState(initial);

  const handleToggle = () => {
    const newState = !isOn;
    setIsOn(newState);
    // if (onToggle) onToggle(newState);
    if (onToggle) onToggle(newState, row);
  };

  return (
    <button
      onClick={handleToggle}
      className={`w-16 h-8 flex items-center rounded-full p-1 transition-all ${
        isOn ? "bg-green-500" : "bg-gray-400"
      }`}
    >
      <div
        className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-all ${
          isOn ? "translate-x-8" : "translate-x-0"
        }`}
      ></div>
    </button>
  );
};

export default ToggleButton;
