import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Grid,
  Chip,
  Divider,
} from "@mui/material";

const OfferIndividual = () => {
  const { id } = useParams();
  const [offer, setOffer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOfferDetails();
  }, [id]);

  const fetchOfferDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.medstown.com/offer/promocode/${id}`
      );

      setOffer(response.data.data);
    } catch (error) {
      console.error("Error fetching offer details:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!offer) {
    return (
      <Typography variant="h6" align="center" color="error">
        Offer not found
      </Typography>
    );
  }

  return (
    <Card sx={{ maxWidth: 600, mx: "auto", mt: 4, p: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
        >
          {offer.code}
        </Typography>

        {/* Partner Details */}
        {offer.collabrate_partner?.title && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mb={3}
            p={2}
            borderRadius={2}
            border={"1px solid #ddd"}
            bgcolor="white"
          >
            <Box
              component="img"
              src={offer.collabrate_partner.img_url}
              alt={offer.collabrate_partner.title}
              sx={{
                width: 100,
                height: 80,
                borderRadius: "8px",
                objectFit: "cover",
                border: "1px solid #ddd",

                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.05)" },
              }}
              // onError={(e) => {
              //   e.target.onerror = null;
              //   e.target.src = "/";
              // }}
            />

            <Typography
              variant="h6"
              fontWeight="bold"
              mt={1}
              textAlign="center"
            >
              {offer.collabrate_partner.title}
            </Typography>
          </Box>
        )}

        {/* Offer Type */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Discount Type:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={
                offer.discount_type === "fixedPrice"
                  ? "Fixed Price"
                  : "Percentage"
              }
              color="primary"
              size="small"
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle2">Discount Value:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {offer.discount_type === "fixedPrice"
                ? `₹${offer.fixedPrice}`
                : `${offer.discount}% (Max ₹${offer.max_discount_amount})`}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle2">Minimum Bill Amount:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              ₹{offer.minimum_bill_amount}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle2">Valid Until:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {new Date(offer.valid_until).toLocaleDateString("en-GB")}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle2">Number of Uses:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{offer.number_of_uses}</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {/* Description */}
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          {offer.description}
        </Typography>

        {/* Offer Status */}
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            color: offer.is_live ? "green" : "red",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {offer.is_live ? "Active" : "Inactive"}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default OfferIndividual;
