import React, { useState, useEffect, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Modal,
  TextField,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { MyContext } from "Contextapi/MyContext";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";
import MyLocation from "Contextapi/MyLocation";
import CloseIcon from "@mui/icons-material/Close";
import Usertotal from "./Usertotal";

const UserDetails = () => {
  const [searchText, setSearchText] = useState("");
  const [usersData, setUsersData] = useState([]);
  const { setUsercount } = useContext(MyContext);
  const [open, setOpen] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    msg: "",
    title: "",
    details: "",
    urls: "",
  });

  const [notifybox, setNotifybox] = useState(false);

  useEffect(() => {
    async function getAllUsers() {
      try {
        setLoading(true);
        const res = await axios.get(
          "https://api.medstown.com/customer/getallusers"
        );
        if (!res.data?.data) {
          throw new Error("Invalid response structure");
        }
        const data = Array.isArray(res.data.data) ? res.data.data : [];
        setUsersData(data.reverse());
        setUsercount(data.length);
      } catch (error) {
        setError(error.message || "Failed to fetch users");
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    }

    getAllUsers();
  }, [setUsercount]);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = usersData.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const handleClose = () => {
    setOpen(false);
    setSelectedUserDetails(null);
  };

  const handledetailsClick = async (userId) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://api.medstown.com/customer/getuser/allorder/${userId}`
      );
      setSelectedUserDetails(res.data); // Store the user details in state
      setOpen(true); // Open the modal
    } catch (error) {
      setError("Failed to fetch user details");
      console.error("Error fetching user details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLocationClick = (lat, long) => {
    if (!lat || !long) {
      setError("Invalid location coordinates");
      return;
    }
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${long}`;
    window.open(googleMapsUrl, "_blank");
  };

  const tableColumns = [
    { field: "fullName", headerName: "Name", width: 150 },
    { field: "phone", headerName: "Phone", width: 100 },
    { field: "email", headerName: "Email", width: 240 },
    { field: "pincode", headerName: "Pincode", width: 80 },
    {
      field: "address",
      headerName: "Location",
      width: 300,
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            {params?.row?.address
              ? ` ${
                  params?.row?.address?.street
                    ? params?.row?.address?.street
                    : ""
                } ${
                  params?.row?.address?.district
                    ? params?.row?.address?.district
                    : ""
                } ${
                  params?.row?.address?.city ? params?.row?.address?.city : ""
                } ${
                  params?.row?.address?.region
                    ? params?.row?.address?.region
                    : ""
                } ${
                  params?.row?.address?.postalCode
                    ? params?.row?.address?.postalCode
                    : ""
                }`
              : "NA"}
          </>
        );
      },
    },
    {
      field: "datetime",
      headerName: "Date / Time",
      width: 300,
      renderCell: (params) => {
        const date = new Date(params.value || null);
        return (
          <div>
            {date.toDateString()} - {date.toLocaleTimeString()}
          </div>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div className="flex items-center">
          <FaInfoCircle
            size={18}
            onClick={() => handledetailsClick(params?.row?.userId)}
            className="mr-7 cursor-pointer hover:text-blue-500"
          />
          <AiFillDelete
            size={18}
            className="mr-7 cursor-pointer hover:text-red-500"
          />
          {params?.row?.address?.latitude && (
            <FaLocationArrow
              size={18}
              onClick={() =>
                handleLocationClick(
                  params?.row?.address?.latitude,
                  params?.row?.address?.longitude
                )
              }
              className="mr-7 cursor-pointer hover:text-red-500"
            />
          )}
        </div>
      ),
    },
  ];

  const handleDownload = () => {
    try {
      window.location.href =
        "https://api.medstown.com/admin/users/download-csv";
    } catch (error) {
      setError("Failed to download CSV");
      console.error("Download error:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSendNotification = async () => {
    if (!searchText.match(/^\d{6}$/)) {
      setError("Please enter a valid 6-digit pincode");
      return;
    }
    try {
      await axios.post(
        `https://api.medstown.com/customer/userspincode/${searchText}/notiy`,
        formData
      );
      //  console.log(response.data);
      setFormData({
        msg: "",
        title: "",
        details: "",
        urls: "",
      });
      setNotifybox(false);
      setError(null);
      alert(`Notification is running successfully pincode-${searchText}`);
    } catch (error) {
      setError(error.response?.data?.message || "Failed to send notification");
    }
  };

  return (
    <div>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      )}
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <Usertotal />
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchText}
          onChange={handleSearchTextChange}
        />

        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            sx={{
              borderRadius: "4px",
              fontSize: "14px",
            }}
          >
            Download CSV
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setNotifybox(true)}
            sx={{
              borderRadius: "4px",
              fontSize: "14px",
            }}
          >
            Send Notification
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/admin/add-wallet-amount");
            }}
            sx={{
              borderRadius: "4px",
              fontSize: "14px",
            }}
          >
            Add Free Cash
          </Button>
        </div>

        {usersData.length > 0 ? (
          <DataGrid
            rows={filteredRows}
            columns={tableColumns}
            pageSize={5}
            loading={loading}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            sx={{ width: "100%" }}
            getRowId={(row) => row._id}
          />
        ) : (
          !loading && (
            <Typography variant="body1" sx={{ textAlign: "center", my: 3 }}>
              No users found
            </Typography>
          )
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            p: 4,
            backgroundColor: "white",
            borderRadius: 2,
            maxWidth: "80%",
            margin: "auto",
            height: "100vh",
          }}

          // onClick={()=> handleClose() }
        >
          {selectedUserDetails ? (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Order Details for {selectedUserDetails?.customerName}
              </Typography>

              {/* Grid for Side-by-Side Layout */}
              <Grid container spacing={4} mt={3} sx={{ height: "100%" }}>
                {/* Air Order Details - Left Side */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ cursor: "pointer", height: "90%" }}
                >
                  <Paper
                    elevation={3}
                    sx={{ padding: 2, height: "100%", overflowY: "auto" }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Place Order Details
                    </Typography>
                    {selectedUserDetails?.airOrder?.map((item, index) => (
                      <Box key={index} mt={5}>
                        <Typography variant="body1">
                          <strong>Order Type:</strong> {item.type}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Order status:</strong> {item.orderId}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Order ID:</strong> {item.status}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Customer Name:</strong> {item.customerName}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Customer Phone:</strong> {item.customerPhone}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Customer location:</strong>
                          <MyLocation
                            latitude={item?.userLat}
                            longitude={item?.userLng}
                          />

                          {item.customerPhone}
                        </Typography>

                        <Typography variant="body1">
                          <strong>Pharmacy Name:</strong> {item.pharmacyName}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Delivery Boy Name:</strong>{" "}
                          {item.deliveryBoyName || "N/A"}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Total Price:</strong> ₹{item.totalPrice}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Created At:</strong>{" "}
                          {new Date(item.createdAt).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Order Details:</strong>
                        </Typography>

                        {item.orderDetails.map((orderdata, i) => (
                          <Box key={i} ml={2}>
                            <Typography variant="body2">
                              - {orderdata.medicineName} (x
                              {orderdata.medicineQuantity}) - ₹
                              {orderdata.medicinePrice}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Paper>
                </Grid>

                {/* Final Order Details - Right Side */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ cursor: "pointer", height: "90%" }}
                >
                  <Paper
                    elevation={3}
                    sx={{ padding: 2, height: "100%", overflowY: "auto" }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Final Order Details
                    </Typography>
                    {selectedUserDetails?.finalorderdetal?.map(
                      (finalOrder, index) => (
                        <Box key={index} mt={2}>
                          <Typography variant="body1">
                            <strong>Order ID:</strong> {finalOrder.orderId}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Customer Name:</strong>{" "}
                            {finalOrder.customerName}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Customer Phone:</strong>{" "}
                            {finalOrder.customerPhone}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Pharmacy Name:</strong>{" "}
                            {finalOrder?.pharmacyName || null}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Payment Type:</strong>{" "}
                            {finalOrder.paymentType}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Status:</strong> {finalOrder.status}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Total Price:</strong> ₹
                            {finalOrder.totalPrice}
                          </Typography>
                          <Typography variant="body1">
                            <strong>OTP:</strong> {finalOrder.otpValue}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Delivery Boy:</strong>{" "}
                            {finalOrder.deliveryBoyName} -{" "}
                            {finalOrder.deliveryBoyPhone}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Created At:</strong>{" "}
                            {new Date(finalOrder.createdAt).toLocaleString()}
                          </Typography>
                          <Typography variant="body1">
                            <strong>Order Details:</strong>
                          </Typography>
                          {finalOrder.orderDetails.map((order, i) => (
                            <Box key={i} ml={2}>
                              <Typography variant="body2">
                                - {order.medicineName} (x
                                {order.medicineQuantity}) - ₹
                                {order.medicinePrice}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      )
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </Box>
      </Modal>

      {/* Dialog */}
      <Dialog open={notifybox} onClose={() => setNotifybox(false)} fullWidth>
        <DialogTitle>
          Send Notification
          <IconButton
            aria-label="close"
            onClick={() => setNotifybox(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <TextField
            label="Title"
            name="title"
            value={formData?.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Message"
            name="msg"
            value={formData?.msg}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Details"
            name="details"
            value={formData?.details}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            variant="outlined"
          />
          <TextField
            label="URL"
            name="urls"
            value={formData?.urls}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Close
          </Button>
          <Button
            onClick={handleSendNotification}
            variant="contained"
            color="primary"
          >
            Send Notification
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
