import React from "react";
import SignIn from "views/auth/SignIn";
import {
  MdHome,
  MdHealthAndSafety,
  MdStore,
  MdOutlinePedalBike,
  MdOutlineDepartureBoard,
  MdOutlineShoppingBasket,
  MdAddLocation,
  MdAddBusiness,
  MdSavedSearch,
  MdOutlineSettingsInputComposite,
  MdLocalOffer,
} from "react-icons/md";

import { FaUsers } from "react-icons/fa";
import Dashboard from "views/admin/Dashboard/Dashboard";
import Partners from "views/admin/Partners/Partners";
import SaleForce from "views/admin/SaleForce/SaleForce";
import Category from "views/admin/Category/Category";
import { AiOutlinePoweroff } from "react-icons/ai";
import Subcat from "views/admin/Category/Subcat";
import Delivery from "views/admin/SaleForce/Delivery";
import OpenOrders from "views/admin/OpenOrders";
import OrderHistory from "views/admin/OrderHistory";
import Sales from "views/admin/Sales";
import UserDetails from "views/admin/UserDetails";
import PharmacyDetails from "views/admin/PharmacyDetails";
import DeliveryPartnerDetails from "views/admin/DeliveryPartnerDetails";
import PharmacyPayoutReq from "views/admin/PharmacyPayoutReq";
import DeliveryPayoutReq from "views/admin/Delivery Payout Req";
import PincodeSrc from "views/admin/Dashboard/PincodeSrc";
import ReferPharmacy from "views/admin/newScreen/ReferPharmacy";
import Searchhistories from "views/admin/Dashboard/components/Searchhistories";
import SearchHistoriespincode from "views/admin/Dashboard/components/SearchHistoriespincode";
import AdminController from "views/admin/Dashboard/AdminController";
import CreditWallet from "views/admin/Dashboard/CreditWallet";
import OfferList from "views/admin/Dashboard/OfferList";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: "Open Orders",
    layout: "/admin",
    path: "open-orders",
    icon: <MdOutlineDepartureBoard className="h-6 w-6" />,
    component: <OpenOrders />,
    private: true,
  },

  {
    name: "Orders History",
    layout: "/admin",
    path: "orders-history",
    icon: <MdOutlineDepartureBoard className="h-6 w-6" />,
    component: <OrderHistory />,
    private: true,
  },
  {
    name: "Payments Details",
    layout: "/admin",
    path: "sales",
    icon: <MdOutlineShoppingBasket className="h-6 w-6" />,
    component: <Sales />,
    private: true,
  },

  {
    name: "Pharmacy Payout Req",
    layout: "/admin",
    path: "pharmacy-payout-req",
    icon: <MdOutlineDepartureBoard className="h-6 w-6" />,
    component: <PharmacyPayoutReq />,
  },
  {
    name: "Delivery Payout Req",
    layout: "/admin",
    path: "delivery-payout-req",
    icon: <MdOutlineDepartureBoard className="h-6 w-6" />,
    component: <DeliveryPayoutReq />,
  },
  {
    name: "Pharmacy Partner",
    layout: "/admin",
    path: "saleForce",
    icon: <MdStore className="h-6 w-6" />,
    component: <SaleForce />,
  },
  {
    name: "Pharmacy Details",
    layout: "/admin",
    path: "pharmacy-details",
    icon: <MdStore className="h-6 w-6" />,
    component: <PharmacyDetails />,
  },
  {
    name: "ReferPharmacy",
    layout: "/admin",
    path: "referPharmacy",
    icon: <MdAddBusiness className="h-6 w-6" />,
    component: <ReferPharmacy />,
    style: { display: "none" },
  },
  {
    name: "Delivery Boys Location",
    layout: "/admin",
    path: "delivery-list",
    icon: <MdOutlinePedalBike className="h-6 w-6" />,
    component: <Delivery />,
    style: { display: "none" },
  },
  {
    name: "Delivery Partner Details",
    layout: "/admin",
    path: "delivery-partner-details",
    icon: <MdOutlinePedalBike className="h-6 w-6" />,
    component: <DeliveryPartnerDetails />,
  },
  {
    name: "Medicines",
    layout: "/admin",
    path: "partners",
    icon: <MdHealthAndSafety className="h-6 w-6" />,
    component: <Partners />,
  },

  {
    name: "User Details",
    layout: "/admin",
    path: "user-details",
    icon: <FaUsers className="h-6 w-6" />,
    component: <UserDetails />,
  },

  // {
  //   name: "Payments Details", //"WalletDetails
  //   layout: "/admin",
  //   path: "wallet-details",
  //   icon: <MdOutlineWallpaper className="h-6 w-6" />,
  //   component: <WalletDetails />,
  // },
  // {
  //   name: "Catories", //"SaleForce
  //   layout: "/admin",
  //   path: "category",
  //   icon: <MdCategory className="h-6 w-6" />,
  //   component: <Category />,
  // },

  // {
  //   name: "subcat",
  //   layout: "/admin",
  //   path: "subcat",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <Subcat />,
  //   style: { display: "none" },
  // },

  {
    name: "Pincode",
    layout: "/admin",
    path: "Pincode",
    icon: <MdAddLocation className="h-6 w-6" />,
    component: <PincodeSrc />,
    style: { display: "none" },
  },
  {
    name: "Search Histories",
    layout: "/admin",
    path: "Searchhistories",
    icon: <MdSavedSearch className="h-6 w-6  " />,
    component: <Searchhistories />,
    style: { display: "none" },
  },

  {
    name: "User Searches",
    layout: "/admin",
    path: "SearchHistoriespincode",
    icon: <MdSavedSearch className="h-6 w-6  " />,
    component: <SearchHistoriespincode />,
    style: { display: "none" },
  },
  {
    name: "Admin Controller",
    layout: "/admin",
    path: "admin-controller",
    icon: <MdOutlineSettingsInputComposite className="h-6 w-6  " />,
    component: <AdminController />,
    style: { display: "none" },
  },
  {
    name: "Offers",
    layout: "/admin",
    path: "offers",
    icon: <MdLocalOffer className="h-6 w-6  " />,
    component: <OfferList />,
    style: { display: "none" },
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "sign-in",
    icon: <AiOutlinePoweroff className="h-6 w-6" />,
    component: <SignIn />,
  },
  // create multiple routes for sub menu
];
export default routes;
